import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import PlainHeader from "components/ui/base/PlainHeader";
import Customers from "components/ui/extended/Customers";
import ChatCTA from "components/ui/base/ChatCTA";
import Pricing from "components/ui/extended/Pricing";
import QuestionsAndAnswers from "components/ui/base/QuestionsAndAnswers";

interface PricingPageProps {
  location: Location;
}
export default ({ location }: PricingPageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper
      withLayout
      location={location}
      layoutSettings={{ alternativeColors: true }}
    >
      <SEO
        title={t("pages.pricing.seo.title")}
        description={t("pages.pricing.seo.description")}
        image={{
          relativePath: "meta/customerly-live-chat.jpg",
          alt: "Customerly Pricing - Give your customers the support they deserve",
        }}
      />

      <PlainHeader
        primaryBackground
        title={t("pages.pricing.title")}
        description={t("pages.pricing.description")}
        maxWidth={800}
        descriptionMaxWidth={700}
      />

      <Pricing location={location} />

      <QuestionsAndAnswers
        title={t("pages.pricing.faq.title")}
        description={t("pages.pricing.faq.description")}
        elements={[1, 2, 3, 4, 5, 6, 7].map((index) => {
          return {
            question: t(`pages.pricing.faq.${index}.question`),
            answer: t(`pages.pricing.faq.${index}.answer`),
          };
        })}
      />

      <ChatCTA
        title={t("pages.pricing.chatCTATitle")}
        titleMaxWidth={500}
        chatMessage={t("pages.pricing.chatMessage")}
      />

      <Customers />
    </GlobalWrapper>
  );
};
